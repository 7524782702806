<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #left>
          <h5 class="p-m-0">
            {{ $t("customer.list.table_title") }}
          </h5>
        </template>

        <template #right>
          <Button
            label=""
            icon="pi pi-refresh"
            class="p-button-secondary p-mr-2 p-mb*2"
            data-testid="table-refresh"
            @click="onRefreshTable"
          />
        </template>
      </Toolbar>
      <DataTable
        :value="customers"
        :paginator="true"
        :resizable-columns="true"
        column-resize-mode="expand"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column
          field="company"
          :header="translation.company"
          :sortable="true"
        />
        <Column field="contact" :header="translation.contact">
          <template #body="slotProps">
            <span class="p-column-title">{{ translation.contact }}</span>
            {{ slotProps.data.contact.fullName() }}
          </template>
        </Column>
        <Column :header="translation.email" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.contact.email }}
          </template>
        </Column>
        <Column
          field="numberOfClients"
          :header="translation.clients"
          :sortable="true"
        />
        <Column
          field="numberOfEstablishments"
          :header="translation.establishments"
          :sortable="true"
        />
        <Column :header="translation.createdAt" :sortable="true">
          <template #body="slotProps">
            {{ dateToString(slotProps.data.createdAt) }}
          </template>
        </Column>
        <Column style="width: 5rem; text-align: right">
          <template #body="slotProps">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-mr-2"
              :data-testid="slotProps.data.id"
              @click="onConfirmDelete(slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>{{ $t("customer.list.table_empty") }} </template>
      </DataTable>
      <Dialog
        v-model:visible="showDeleteDialog"
        :style="{ width: '450px' }"
        :header="translation.dialogHeader"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span>{{
            $t("customer.modal_delete.body", {
              customer: customerToDelete.company,
            })
          }}</span>
        </div>

        <template #footer>
          <Button
            :label="translation.buttonCancel"
            icon="pi pi-times"
            class="p-button-text"
            data-testid="buttonCancelDelete"
            @click="showDeleteDialog = false"
          />
          <Button
            :label="translation.buttonDelete"
            icon="pi pi-check"
            class="p-button-danger"
            autofocus
            data-testid="buttonConfirmDelete"
            @click="onPressDelete(customerToDelete.id)"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Toolbar from "primevue/toolbar";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../config";
import { dateTransformer } from "../service/utils";
import {
  FETCHING_CUSTOMERS,
  UNREGISTER_CUSTOMER,
} from "../store/modules/customer/customer.actions.type";
import { GET_CUSTOMERS } from "../store/modules/customer/customer.getters.type";

export default {
  name: "TableCustomers",
  components: {
    Button,
    Column,
    DataTable,
    Dialog,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const customers = computed(() => store.getters[GET_CUSTOMERS]);
    const showDeleteDialog = ref(false);
    const customerToDelete = ref({});
    const translation = {
      company: t("customer.list.table_column_company"),
      contact: t("customer.list.table_column_contact"),
      email: t("customer.list.table_column_email"),
      clients: t("customer.list.table_column_clients"),
      establishments: t("customer.list.table_column_establishments"),
      createdAt: t("customer.list.table_column_created_at"),
      dialogHeader: t("customer.modal_delete.header"),
      buttonDelete: t("common.delete"),
      buttonCancel: t("common.cancel"),
    };

    const onRefreshTable = () => store.dispatch(FETCHING_CUSTOMERS);
    const dateToString = (date) => dateTransformer.toString(date);

    function onConfirmDelete(data) {
      showDeleteDialog.value = true;
      customerToDelete.value = { ...data };
    }

    async function onPressDelete(customerId) {
      await store.dispatch(UNREGISTER_CUSTOMER, customerId);
      showDeleteDialog.value = false;
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("customer.modal_delete.toast"),
        life: TOAST_LIFE,
      });
    }

    return {
      customers,
      customerToDelete,
      showDeleteDialog,
      onRefreshTable,
      onConfirmDelete,
      onPressDelete,
      dateToString,
      translation,
    };
  },
};
</script>
